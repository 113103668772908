
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ParameterList from "@/ComponentsSystem/Ht-Tr-parameter/ParameterList.vue";

export default defineComponent({
  name: "projects",
  components: {
    ParameterList,
  },
  data(): {
    DateType: string;
    startDate: string;
    endDate: string;
    Step: number;
  } {
    return {
      DateType: "",
      startDate: "",
      endDate: "",
      Step: 1,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("工單進度回報", ["工單與來料管理"]);
    });
  },
});
