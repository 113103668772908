
import { defineComponent } from "vue";
import List from "@/data/Ht-Tr-parameter/List.json";
import moment from "moment";

export default defineComponent({
  name: "kt-widget-12",
  components: {},
  props: {
    widgetClasses: String,
  },
  data(): {
    Auth: any;
    Name: any;
    startDate: string;
    endDate: string;
    Step: number;
    List: Array<any>;
    Total: any;
    Page: any;
    Item: any;
  } {
    return {
      Auth: {},
      Name: "",
      startDate: "",
      endDate: "",
      Step: 1,
      List: [],
      Total: 0,
      Page: 1,
      Item: {},
    };
  },
  methods: {
    async Init() {
      this.List = [];
      let str: any;
      str = localStorage.getItem("Auth");
      this.Auth = JSON.parse(str);
      this.Name = this.Auth.Name;
         List.sort((a, b) => {
            let aDate = moment(new Date(a.ETA)).format("YYYY-MM-DD");
            let bDate = moment(new Date(b.ETA)).format("YYYY-MM-DD");
            // return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
            return Date.parse(aDate) - Date.parse(bDate);
          });
      switch (this.Name) {
        case "Root":
          // console.log(List[395])
          this.List = List;
          break;

        case "上翊":
          this.List = List.filter(item => {
            return item.Vendor == "上翊";
          });
          break;
        case "超正":
          this.List = List.filter(item => {
            return item.Vendor == "超正";
          });
          break;
        case "升大":
          this.List = List.filter(item => {
            return item.Vendor == "升大";
          });
          break;
        case "國敏":
          this.List = List.filter(item => {
            return item.Vendor == "國敏";
          });
          break;
      }
    },
    async SwitchPage(page) {
      if (page !== 0) {
        this.Page = page * 10 + 1;
      } else {
        this.Page = page + 1;
      }
    },
    async Detail(item) {
      this.Item = item;
      this.Step = 2;
    },
    async New() {
      this.Item = {};
      this.Step = 3;
    },
    async Save() {
      alert("儲存成功");
      this.List.unshift(this.Item);
      this.Step = 1;
    },
  },
  async created() {
    await this.Init();
  },
});
